import { XOR } from '@dltru/dfa-models'
import { InputNumber } from 'antd'
import React, { FC, ReactElement, ReactNode } from 'react'

import { Box } from '../../../../../Box'
import { FormItem } from '../../../../../components/Form'
import { Price } from '../index'
import style from './style.m.less'

export type IBodyInputBlock = {
    title: string | ReactNode
    explain?: any
} & XOR<
    XOR<
        {
            read: boolean
            valueCurrency?: string
        },
        {
            placeholder?: string
            prefix?: any
            value?: string | number
            name: string
            handleInputChange?: any
            rules?: any
        }
    >,
    { children: React.ReactNode }
>

export const BodyInputBlock: FC<IBodyInputBlock> = ({
    title,
    explain,
    placeholder,
    prefix,
    read,
    value,
    name,
    valueCurrency,
    handleInputChange,
    rules = [],
    children,
}) => {
    const InputComponent = children ? (
        children
    ) : (
        <FormItem name={name} rules={[...rules]} className={style.inputBlockFormItem}>
            <InputNumber
                placeholder={placeholder}
                prefix={prefix}
                value={value}
                onChange={handleInputChange}
            />
        </FormItem>
    )
    return (
        <div className={style.inputBlock}>
            <span className={style.inputBlockTitle}>{title}</span>
            {read ? (
                <Box padding={[0.5, 0]}>
                    <Price price={value} currency={valueCurrency} />
                </Box>
            ) : (
                InputComponent
            )}
            <p className={style.inputBlockExplain}>{explain}</p>
        </div>
    )
}
